/**
 * 根据Map value 获取 key
 *
 * @param {Map} map - Map数据
 * @param {string} targetVal - 目标key的valuefocus.
 * @returns {string} key - 目标key
 */
export function getKeyByValue(map, targetVal) {
  for (const [key, value] of map.entries()) {
    if (value === targetVal) {
      return key;
    }
  }
  return null;
}

/**
 * 国家简码与多语言标识映射关系
 */
export const COUNTRY_LANG = new Map([
  ['US', 'en-US'],
  ['GB', 'en-GB'],
  ['CA', 'en-CA'],
  ['AU', 'en-AU'],
  ['EU', 'en-EU'],
  ['DE', 'de-DE'],
  ['JP', 'ja-JP'],
  ['FR', 'fr-FR'],
  ['IT', 'it-IT'],
  ['ES', 'es-ES'],
  ['KR', 'ko-KR']
])

/**
 * 国家简码与客服邮箱映射关系
 */
 export const COUNTRY_SERVER = new Map([
  ['US', 'hello@jackery.com'],
  ['GB', 'hello.eu@jackery.com'],
  ['CA', 'hello@jackery.com'],
  ['AU', 'hello.aus@jackery.com'],
  ['EU', 'hello.eu@jackery.com'],
  ['DE', 'hello.eu@jackery.com'],
  ['JP', 'jackery.jp@jackery.com'],
  ['KR', 'hello.kr@jackery.com'],
  ['FR', 'hello.eu@jackery.com'],
  ['IT', 'hello.eu@jackery.com'],
  ['ES', 'hello.eu@jackery.com']
])

/**
 * 国家简码与退换货条款映射关系
 */
 export const COUNTRY_AFTER_SALE = new Map([
  ['US', 'https://www.jackery.com/pages/policy'],
  ['GB', 'https://uk.jackery.com/pages/policy'],
  ['CA', 'https://ca.jackery.com/pages/warranty-return'],
  ['AU', 'https://au.jackery.com/pages/warranty-and-return'],
  ['EU', 'https://eu.jackery.com/support/warranty-return'],
  ['DE', 'https://de.jackery.com/pages/policy'],
  ['JP', 'https://www.jackery.jp/pages/warranty-and-returns'],
  // ['KR', 'hello.kr@jackery.com'],
  ['FR', 'https://fr.jackery.com/policies/refund-policy'],
  ['IT', 'https://it.jackery.com/policies/refund-policy'],
  ['ES', 'https://es.jackery.com/policies/refund-policy']
])

/**
 * 国家简码与政策映射关系
 */
 export const COUNTRY_TERMS = new Map([
  ['US', 'https://www.jackery.com/policies/terms-of-service'],
  ['GB', 'https://uk.jackery.com/policies/terms-of-service'],
  ['CA', 'https://ca.jackery.com/policies/terms-of-service'],
  ['AU', 'https://au.jackery.com/policies/terms-of-service'],
  ['EU', 'https://eu.jackery.com/policies/terms-of-service'],
  ['DE', 'https://de.jackery.com/policies/terms-of-service'],
  ['JP', 'https://www.jackery.jp/pages/terms-of-service'],
  // ['KR', 'hello.kr@jackery.com'],
  ['FR', 'https://fr.jackery.com/policies/terms-of-service'],
  ['IT', 'https://it.jackery.com/policies/terms-of-service'],
  ['ES', 'https://es.jackery.com/policies/terms-of-service']
])

/**
 * 国家简码与隐私条款映射关系
 */
 export const COUNTRY_POLICY = new Map([
  ['US', 'https://www.jackery.com/policies/privacy-policy'],
  ['GB', 'https://uk.jackery.com/policies/privacy-policy'],
  ['CA', 'https://ca.jackery.com/policies/privacy-policy'],
  ['AU', 'https://au.jackery.com/policies/privacy-policy'],
  ['EU', 'https://eu.jackery.com/policies/privacy-policy'],
  ['DE', 'https://de.jackery.com/policies/privacy-policy'],
  ['JP', 'https://www.jackery.jp/policies/privacy-policy'],
  // ['KR', 'hello.kr@jackery.com'],
  ['FR', 'https://fr.jackery.com/policies/privacy-policy'],
  ['IT', 'https://it.jackery.com/policies/privacy-policy'],
  ['ES', 'https://es.jackery.com/privacy-policy']
])

/**
 * 货币简码与货币符号映射关系
 */
 export const CURRENCY_SYMBOL = new Map([
  ['CNY', '¥'],
  ['HKD', '$'],
  ['TWD', '$'],
  ['USD', '$'],
  ['GBP', '£'],
  ['EUR', '€'],
  ['CAD', '$'],
  ['AUD', '$'],
  ['JPY', '¥']
])
