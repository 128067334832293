import router, { dynamicRoutes } from './config.js';
import store from '../store/index.js';
import { cookie, getUrlParam } from "../utils/common.js";
import { shopifyLogin, shopifyUserCheck } from "../api/userCommon.js";
import ClientMonitor from 'skywalking-client-js';
// import NProgress from 'nprogress';
// import 'nprogress/nprogress.css';

import i18n from "../lang";
let { t } = i18n.global;

const permission = (path) => {
  if (store.state.permission.permissionList) {
    location.pathname === '/' && router.push({ path });
    return
  }

  store.commit('permission/SET_PERMISSION', ["*"]);
  store.dispatch('permission/FETCH_PERMISSION').then(() => {
    router.push({ path })
  })
}

// vue-router 钩子函数beforeEach()对路由进行判定
const nextLoad = (path) => {
  console.log("vue-router 钩子函数beforeEach()对路由进行判定")
  console.log(router)
  const r = dynamicRoutes.find(item => item.path === path);
  // return r ? { path: '/login', query: { redirect: path } } : '/404'
  if(r) {
    if(cookie.get("_jk_user") && cookie.get("_jk_id")) {
      try {
        permission(path)
      } catch (error) {
        return { path: '/login', query: { redirect: path } }
      }
    } else {
      return { path: '/login', query: { redirect: path } }
    }
  } else {
    return '/404'
  }
};


/**
 * ID系统只包含基础界面和/id
 * user包含权限路由的非/id部分，需要跳转到域名membership.jackery.com
 */
// router.beforeEach((to, from, next) => {

//   next()
//   return
//   if (!store.state.permission.permissionList && localStorage.getItem('JACKERY_ID_PER')) {
//     store.commit('permission/SET_PERMISSION', JSON.parse(localStorage.getItem('JACKERY_ID_PER')));
//     store.dispatch('permission/FETCH_PERMISSION').then(() => next({ ...to, replace: true }));
//     return
//   }

//   let target = store.state.personal,
//     redirect = getUrlParam('redirect'),
//     checkout_url = getUrlParam("checkout_url");

//   const init = () => {
//     NProgress.start();

//     if (to.matched.length === 0) {
//       next(nextLoad(to.path));
//       return
//     }
//     // 需要登录的路由必须有token
//     if (to.meta.requireAuth) {
//       cookie.get("_jk_id") || cookie.get("_jk_user") || localStorage.getItem('JACKERY_ID_TOKEN') || localStorage.getItem('JACKERY_USER_TOKEN')
//         ? next()
//         // : next({ path: '/login', query: { redirect: redirect || to.fullPath } })
//         : next({ path: '/id' })
//     } else {
//       next()
//     }
//   }

//   const uLogin = () => {
//     let _t = cookie.get("_jk_id") || localStorage.getItem('JACKERY_ID_TOKEN');
//     const shop_id = cookie.get('_jk_shop_id') || localStorage.setItem("JACKERY_ID_SHOP_ID");
//     let param = {
//       token: _t.replace("bearer ", ""),
//       return_url: checkout_url ? decodeURIComponent(checkout_url) : `${location.origin}`,
//       shop_id,
//       back: redirect
//     };

//     shopifyLogin(param).then(res => {
//       localStorage.setItem("JACKERY_USER_TOKEN", res.jackery_token);
//       cookie.set("_jk_user", res.jackery_token);
//       delete param.token;
//       param.back === "/" && (param.back = "/account/overview");
//       sessionStorage.setItem("JACKERY_ID_REF", JSON.stringify(param));

//       window.location.href = res.redirect_url
//     }).catch(() => {
//       location.pathname === '/' && (window.location.href = `${import.meta.env.VITE_ID_URL}/login?redirect=${redirect || to.path}`)
//     })
//   }

//   switch (location.origin) {
//     case import.meta.env.VITE_ID_URL:
//       if (target.indexOf(to.path) >= 0 && to.path !== '/') {
//         let url = `${import.meta.env.VITE_MY_URL}?Authorization=${cookie.get("_jk_id") || localStorage.getItem('JACKERY_ID_TOKEN')}&jackery_token=${localStorage.getItem('JACKERY_USER_TOKEN') || cookie.get("_jk_user") || ""}&redirect=${to.path}`;
//         window.location.href = url
//       } else {
//         init()
//       }
//       break;
//     case import.meta.env.VITE_MY_URL:
//       if ((redirect && target.includes(redirect)) || target.includes(redirect) || ["/index","/index/guide"].includes(to.path)) {
//         // 直接进入会员中心, 要先判断是否存在user token, 若存在user token, 需要对齐验证是否有效
//         (cookie.get("_jk_user") || localStorage.getItem("JACKERY_USER_TOKEN")) ? (function () {
//           sessionStorage.setItem("TEMP_JUMP_VERIFY", "1");
//           shopifyUserCheck({}).then(() => init()).catch(() => uLogin()).finally(() => { sessionStorage.removeItem("TEMP_JUMP_VERIFY") })
//         })() : init()
//       } else {
//         let url = to.path === "/login" ? `${import.meta.env.VITE_ID_URL}/login?active=logout` : `${import.meta.env.VITE_ID_URL}${to.path}${location.search}`;

//         window.location.href = url
//       }
//       break;

//     default: init(); break;
//   }
// });

// router.afterEach((to) => {
//   let title = '';
//   switch (to.name) {
//     case "id": title = t('header.userCenter'); break;
//     case "accountOverview": case "accountOrder": case "accountAddress": case "accountSettings":
//       title = t('header.personalCenter');
//       break;
//     default: title = import.meta.env.VITE_APP_TITLE; break;
//   }
//   setTimeout(() => {
//     document.title = `${t(to.meta.title)} - ${title}`;
//     document.querySelector('meta[name="description"]').content = t(to.meta.description);

//     // 加入监控 sky walking
//     ClientMonitor.setPerformance({
//       // collector: import.meta.env.VITE_SKY_WALKING,
//       service: `${import.meta.env.VITE_APP_TITLE} [${import.meta.env.VITE_MODE_NAME}]`,
//       serviceVersion: document.querySelector('meta[name="version"]').content,
//       pagePath: location.href,
//       useFmp: true,
//       vue: 'Vue'
//     })
//   }, 500);
//   NProgress.done()
// });

export default router;
