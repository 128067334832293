import { getUrlParam, cookie } from "../common";
import { getIDToken, getUserToken } from "./auth"
/**
 * 无刷新更新url - 删除指定params并返回新url
 *
 * @param {string} param - URLSearchParams key
 */
export function delUrlParam(param) {
    const queryParams = new URLSearchParams(window.location.search);
    if(!param || typeof(param) !== "string") {
        console.error("delUrlParam error: 非法参数， 参数必须为string类型");
        return;
    }
    queryParams.delete(param);
    if (location.hash) {

    }
    const newSearchParam = queryParams.toString();
    
    const newUrl = newSearchParam ? `${window.location.origin}${window.location.pathname}?${queryParams.toString()}${window.location.hash}` : `${window.location.origin}${window.location.pathname}${window.location.hash}`;

    // 修改浏览器地址栏的 URL
    window.history.replaceState(null, '', newUrl);
    return newUrl;
}

/**
 * 无刷新更新url - 添加指定params并返回新url
 *
 * @param {string} param - URLSearchParams key
 */
 export function addUrlParam(key, value) {
    var url = new URL(window.location.href);
    url.searchParams.set(key, value);
    // 修改浏览器 URL，并添加查询参数，但不刷新页面
    history.pushState({}, '', url.toString());
    return url.toString()
}


/**
 * 从本地存储中获取当前站点信息
 */
export function getCurrentStore() {
    return {
        shop_id: cookie.get("_jk_shop_id"),   // 店铺id
        domain: cookie.get("_jk_domain"),     // 店铺地址
        cr: cookie.get("_jk_cr"),             // 店铺国家简码
    }
}

/**
 * 返回上一级 - Fix 解耦APP.vue全局变量
 */
export const go = () => window.history.go(-1);

/**
 * 将语言标识设置到htm lang属性
 */
export function setHtmlPageLang(v) {
  document.querySelector('html')?.setAttribute('lang', v || "en");
}

/**
 * 根据url参数判断是否为三方登录进入
 * @returns {object | boolean} 
 * false：非三方登录回调链接； 
 * object.emailExist=1：三方登录并已绑定邮箱
 * object.emailExist=0：三方登录但未绑定邮箱
 */
 export function isThirdLogin() {
    const emailExist = Number(getUrlParam("emailExist")) || 0;
    const uid = getUrlParam("uid");
    const source = getUrlParam("source");
    const belongBrandChannel = getUrlParam("belongBrandChannel");
    if(uid && source && belongBrandChannel) {
        if(!emailExist) {
            return {
                emailExist: 0,
                uid,
                source,
                belongBrandChannel
            };
        }
        return {
            emailExist: 1,
            uid,
            source,
            belongBrandChannel
        };
    } else {
        return false;
    }
}

/**
 * 根据url参数判断是否携带token自动登录
 * @returns {boolean}
 */
 export function isTokenLogin() {
    const _Authorization = getUrlParam("Authorization") || '';
    const Authorization = decodeURI(_Authorization).replace("bearer+", "bearer ");
    if(Authorization) {
        if(getIDToken() !== Authorization) {
            return Authorization
        }
    }
    return false
}

/**
 * 根据url参数判断是否携带jackery_token自动登录
 * @returns {boolean}
 */
 export function isJackeryTokenLogin() {
    const token = getUrlParam("jackery_token") || '';
    const jackeryToken = decodeURI(token);
    if(jackeryToken) {
        if(getUserToken() !== jackeryToken) {
            return jackeryToken
        }
    }
    return false
}

/**
 * 返回登录页面：
 */
 export const backToPath = (path) => {
    if(location.pathname !== path) {
        window.location.href = `${location.origin}${path}`
    }
}
