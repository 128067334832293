export default {
  "login": {
    "sign": "登錄",
    "login": "登錄",
    "remember": "記住我",
    "about": "關於我們",
    "illegal_email": "郵箱格式不正確",
    "illegal_password": "您的密碼需6-16比特，必須包含字母和數位。",
    "password_matching": "這兩個密碼不一致",
    "enterEmail": "請輸入郵箱或Jackery ID",
    "enter_code": "請輸入驗證碼",
    "forgot": "忘記密碼?",
    "create": "創建我的Jackery ID",
    "thirdLogin": "或通過以下方式登錄",
    "accountActivate": "帳戶啟動",
    "bindEmail": "綁定您的郵箱",
    "error": "登錄失敗，請稍後再試"
  },
  "policies": {
    "content": "通過第三方賬戶登錄，即表示您已經閱讀並同意了我們的",
    "policy-link": "https://www.jackery.com/policies/privacy-policy",
    "policy": "隱私政策",
    "and": "和",
    "terms-link": "https://www.jackery.com/policies/terms-of-service",
    "terms": "使用條款",
    "comma": "。",
    "footer": "Jackery將會使用您選擇的第三方所提供的信息進行註冊或登錄。"
  },
  "reset": {
    "modify": "重置密碼",
    "back": "返回登錄",
    "success": "新密碼設置成功!",
    "referrer": "即將自動跳轉回發起頁面",
    "seconds": "s"
  },
  "register": {
    "title": "註冊",
    "enterEmail": "請輸入郵箱",
    "agree": "我已閱讀並同意遵守Jackery的",
    "agree_after": "",
    "getDeals": "同意獲取最新的產品、促銷、服務等信息",
    "login": "立即登錄",
    "success": "您的賬戶 {email} 註冊成功!",
    "welcomeEmail": "歡迎郵件已經發送到您的郵箱。"
  },
  "header": {
    "logout": '登出',
    "userCenter": "會員中心",
    "personalCenter": "個人中心",
    "shopping": "前往店鋪",
    "setting": "設置",
    "myJackery": "賬號中心",
    "jackeryID": "個人中心"
  },
  "footer": {
    "cookieContent": "我們在本網站上使用 cookie，包括第三方 cookie，以便網站正常運行並分析流量、提供增強的功能、社交媒體功能以及個性化內容和廣告。",
    "cookieUrl": "#",
    "learn": "了解更多",
    "accept": "接受 Cookies",
    "copyright": "Copyright © {date} Jackery Inc. All Rights Reserved.",
    "sitemap-link": "#",
    "sitemap": "站點地圖"
  },
  "account": {
    "welcome": "歡迎您",
    "email": "郵箱",
    "registerEmail": "註冊郵箱",
    "bindNewRegisterEmail": "新郵箱",
    "emailIsEqual": "新綁定的註冊郵箱和原郵箱相同，無須修改。",
    "settings": "賬戶設置",
    "settingTips": "快速管理您的賬戶資料和賬戶安全。",
    "titleProfiles": "個人資料",
    "titleSecurity": "賬戶安全",
    "titleAuthorization": "授權綁定",
    "nickname": "昵稱",
    "name": "姓名",
    "gender": "性別",
    "birthday": "生日",
    "contactEmail": "聯系郵箱",
    "contactPhone": "聯系電話",
    "enterprise": "企業名稱",
    "setted": "已設置",
    "unset": "未設置",
    "password": "密碼",
    "avatarSettings": "頭像設置",
    "choosePicture": "選擇圖片",
    "pop_password": "設置密碼",
    "apply": "應用",
    "secrecy": "保密",
    "male": "男性",
    "female": "女性",
    "currentPassword": "當前密碼",
    "newPassword": "新密碼",
    "newShowAgain": "不再显示",
    "againPassword": "確認新密碼",
    "jackeryIdTips": "Jackery ID只能修改一次, 字符長度在6-32位之間。",
    "birthdayTips": "請告訴我您的生日，以便於為您提供更好的服務。我們會妥善保管您的資料。",
    "nickNameTips": "昵稱可以是任意文字和字符，長度不超過32個字符",
    "nameTips": "建議您填寫真實的姓名，以便於我們與您聯系。我們會妥善保管您的資料。",
    "genderTips": "請告訴我您真實的性別，以便於為您提供更好的服務。我們會妥善保管您的資料。",
    "contactEmailTips": "請設置您常用的郵箱，以便於我麼通過該郵箱與您取得聯系。我們會妥善保管您的資料。",
    "phoneNumberTips": "請設置您常用的電話號碼，以便於我麼通過該郵箱與您取得聯系。我們會妥善保管您的資料。",
    "phoneNumberTips2": "電話號碼只支持數字、括弧和連字符",
    "enterpriseNameTips": "如果您是企業賬戶，可以告訴我們您的企業信息，以便於更好為您提供服務。",
    "emailTips": "註冊郵箱是您的登錄賬戶。為了您的賬戶安全，更換註冊郵箱需要進行安全驗證。",
    "passwordTips": "為了賬戶安全，請勿設置過於簡單的密碼",
    "location": "站點"
  },
  "router": {
    "home": "",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "註冊",
    "login": "登錄",
    "reset": "重置密碼",
    "dashboard": "Dashboard",
    "commonSetting": "通用設置",
    "account": "賬戶設置",
    "accountOverview": "總覽",
    "myProfile": "個人資料",
    "myOrders": "我的訂單",
    "personalSettings": "個人設置",
    "addressBook": "地址簿",
    "accountSettings": "帳戶設置",
    "myDevices": "我的設備",
    "activate": "賬戶激活",
    "myWishlist": "心愿单",
    "myReferral": "My Referral",
  },
  "description": {
    "home": "",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "創建我的Jackery ID, 加入我們~ 成為Jackery會員。",
    "login": "Jackery ID, 顧客登錄",
    "reset": "重置密碼",
    "dashboard": "Dashboard",
    "commonSetting": "通用設置",
    "account": "Jackery ID 用戶帳戶中心",
    "accountOverview": "總覽",
    "myProfile": "個人資料",
    "myOrders": "我的訂單",
    "personalSettings": "個人設置",
    "addressBook": "地址簿",
    "accountSettings": "帳戶設置",
    "myDevices": "我的設備",
    "myReferral": "我的推薦",
    "activate": "創建您的密碼以激活您的帳戶",
    "registerActivate": "請點擊按鈕激活您的賬號, 激活以後您可以使用該賬號登錄我們的網站",
    "myReturn": "我的退換貨"
  },
  "operate": {
    "enable": "啟用",
    "disable": "禁用",
    "confirm": "確認",
    "cancel": "取消",
    "remove": "移除",
    "search": "搜索",
    "reset": "重置",
    "add": "新增",
    "close": "關閉",
    "edit": "編輯",
    "yes": "是",
    "no": "否",
    "submit": "提交",
    "next": "下一步",
    "prev": "上一步"
  },
  "tips": {
    "sure": "你確定嗎?",
    "placeholder": "請輸入",
    "enterConsultContent": "請輸入的你諮詢內容",
    "all": "所有",
    "custom": "自定義",
    "pending": "待處理",
    "loading": "處理中...",
    "loading2": "處理中",
    "loaded": "已處理",
    "loaded2": "已完成",
    "cancelled": "已取消",
    "changeShop": "切換店鋪...",
    "logoutConfirm": "您確定要退出嗎?",
    "loggedOut": "註銷成功",
    "refreshed": "已刷新",
    "noDescribe": "沒有描述",
    "reminder": "溫馨提示",
    "commError": "發生了一點問題",
    "reLogin": "請重新登錄",
    "verify": "驗證碼", "sendVerify": "驗證碼已發送，請您留意您的Email收件箱或者垃圾箱。",
    "getVerifyCode": "獲取驗證碼",
    "verifyError": "驗證碼不正確",
    "homePage": "首頁",
    "404": "抱歉，您訪問的頁面不存在",
    "404Tips": "請檢查輸入的網址是否正確，或點擊鏈接繼續瀏覽",
    "500": "哎呀，出了點問題",
    "500Tips": "服務器錯誤 500，我們深表歉意並正在解決問題。",
    "500Tips2": "請稍後再試。",
    "urlExpired": "驗證鏈接已過期",
    "accountNotActivated": "帳戶未啟動",
    "requestFrequently": "請勿頻繁點擊，驗證後重新操作",
    "accountActivatedSuccess": "賬戶激活成功",
    "submitSuccess": "提交成功",
    "zipVerifyError": "郵政編碼校驗失敗",
    "otherError": "發生了一點問題, 請聯繫管理員。 code: {code}",
    "wrongPasswordMultipleTimes": "您连续输入多次错误密码，请{minute}分钟后重试。",
    "networkError": "網路錯誤",
    "thirdLoginError": "獲取用戶資訊失敗，請稍後重試，或用帳號密碼登入",
    "fileTypeError": "文件類型錯誤, 只允許上傳: {type}類型文件"
  },
  "placeholder": {
    "email": "郵箱 *",
    "emailOrJackeryID": "當前郵箱或Jackery ID *",
    "password": "密碼 *",
    "passwordAgain": "確認新密碼 *",
    "enterprise": "企業名稱",
    "code": "驗證碼 *",
    "firstName": "名",
    "lastName": "姓",
    "select": "請選擇",
    "address1": "街道和號碼等",
    "address2": "公寓、單元、建築、樓層等（可選）",
    "country": "國家/地區",
    "province": "州/省/地區",
    "city": "您的城市",
    "region": "地區",
    "zip": "城市所屬的郵政編碼",
    "zipError": "郵政編碼不正確",
    "phone": "您的電話",
    "company": "公司名稱（可選）",
    "isNotEmpty": "此項不能為空",
    "pobox": "我們不送貨到郵政信箱",
    "minChar2": "最少2個字符",
    "inputChar": "字符長度在 {length} 位之間"
  },
  "order": {
    "sn": "訂單號",
    "detail": "查看物流詳情",
    "productName": "產品名稱",
    "quantity": "數量",
    "price": "產品價格",
    "status": "訂單狀態",
    "consult": "咨詢",
    "contact": "聯系我們",
    "total": "合計",
    "empty": "現時還沒有任何訂單 ~",
    "questionType": "問題類型",
    "shipped": "已發貨",
    "return": "退/換",
    "applied": "已退貨退款",
    "revoke": "撤銷",
    "serviceType": "請選擇服務類型",
    "serviceType_1": "退貨退款",
    "serviceType_2": "換貨",
    "packageArrived": "是否已收到貨",
    "reason": "請選擇原因",
    "reason_1": "{day}天無理由退貨",
    "reason_2": "產品質量問題",
    "reason_3": "其它",
    "quality": "請輸入質量問題",
    "productSerialNumber": "請輸入產品序列號",
    "selectReturnProduct": "請選擇退換的產品",
    "returnQuantity": "請輸入退/換件數",
    "shippingAddress": "收貨地址",
    "returnAddress": "退货地址",
    "revise": "修改",
    "uploadVideo": "上傳視頻",
    "uploadLoading": "視頻上傳中",
    "videoLimit": "視頻大小不能超過{limit}M!",
    "uploadVideoError": "上傳視頻失敗>_<",
    "failureDetermination": "產品故障判斷",
    "steps_1": "1. 電站故障排除步驟",
    "steps_1_content": "1) 請按顯示屏看指示燈是否亮。\n2) 請按住電源按鈕（命名顯示）10秒鐘以重置電源並放置一段時間後再給電源充電。\n3) 在插入設備並為其充電之前，請按下 AC/DC 插座頂部的按鈕以檢查燈是否亮起。\n4)請檢查您的設備是否可以再次正常充電。\n\n如果上述方法不起作用，請將以下信息發送給我們，以便我們進一步幫助您。\n1)訂單號；\n2)序列號（電站底部或電站背面右上角）；\n3) 可以清楚地顯示問題的視頻（上傳到Google Drive並將鏈接發送給我們）。\n4) 您當前的地址。\n\n",
    "steps_2": "2. 太陽能電池板故障排除步驟",
    "steps_2_content": "1) 請使用提供的交流充電器給電站充電，看是否可以工作。\n2) 請在中午強烈的陽光下測試。將太陽能電池板與發電站連接，確保面板上沒有陰影。\n\n如果交流充電器可以很好地為電站充電，請將以下信息發送給我們，以便我們進一步為您提供幫助。\n1) 訂單號；\n2) 序列號（在手柄條碼下方或USB口對面）；\n3) 可以清楚地顯示問題的視頻（上傳到Google Drive並將鏈接發送給我們）。\n4) 您當前的地址。",
    "learnMore": "查看更多",
    "shrink": "收起",
    "noReason_30_day": "1. Jackery 為直接從 Jackery.com 購買的商品提供 30 天退款保證。我們相信您會喜歡您的新 Jackery 產品。但是，如果您因任何原因不滿意，只需在 30 天內申請退貨換貨或退款。\n\n2. 請註意：不接受超過 30 天無故退貨。\n\n3. 為了根據 30 天退貨政策退回您的產品，請檢查： 確保退回的產品采用原包裝，包括所有材料，處於全新或全新狀態.",
    "selectDelivery": "請選擇退貨物流",
    "enterTrackNumber": "請輸入物流跟蹤號",
    "illegal_trackNumber": "請輸入正確的物流單號",
    "enterDescription": "請輸入具體描述"
  },
  "bank": {
    "name": "銀行名",
    "branchName": "支行名",
    "type": "銀行卡種類",
    "account": "銀行卡賬號",
    "realName": "持卡人姓名"
  },
  "return": {
    "orderNumber": "訂單號",
    "status": "退/换货状态",
    "attachment": "標簽附件",
    "confirmRevoke": "請確認是否撤銷退/換貨申請?",
    "returnPolicy_link": "https://www.jackery.com/pages/policy",
    "returnPolicy_1": "返修/退换货申请常见问题，查看",
    "returnPolicy_2": " '售后政策'; ",
    "returnPolicy_3": "如有问题请联系",
    "returnPolicy_4": "support{'@'}jackery.com",
    "returnPolicy_5": ""
  },
  "devices": {
    "empty": "當前未找到任何設備",
    "more": "更多",
    "extendedWarrantyDeadline": "Extended warranty deadline",
    "warrantyStatus": "Extended warranty status",
    "existWarranty": "已延保",
    "nonExistentWarranty": "Non extended warranty",
    "userManual": "User Manual",
    "warrantyInfo": "Just fill out your info below to get started. Once done, we’ll give you four ways to start referring. Email, Facebook, Whatsapp or via your own link. The more friends you refer, the more you can save.",
    "newsletter": "Subscribe to our newsletter",
    "warrantySuccess": "延保成功",
    "download": "下載",
    "none": "暫無"
  },
  "address": {
    "empty": "暫無保存地址 ~",
    "add": "添加新地址",
    "edit": "編輯地址",
    "userName": "用戶名",
    "address1": "地址1",
    "address2": "地址2",
    "country": "國家/地區",
    "country_code": "國家/地區編碼",
    "province": "州/省/地區",
    "province_code": "州/省/地區編碼",
    "city": "城市",
    "zip": "郵政編碼",
    "phone": "電話",
    "company": "公司",
    "default": "設置為默認送貨地址",
    "isDefault": "默認地址",
    "error": "收貨地址存在異常或信息不完整，請檢查"
  },
  "character": {
    "comma": ","
  },
  // Following is server language pack
  "msg": {
    "success": "成功"
  },
  "user": {
    "log": {
      "expire": "請重新登錄"
    }
  },
  "message": {
    "order_status_shipping": "已發貨",
    "order_status_completed": "已完成",
    "order_status_processing": "處理中",
    "order_status_archived": "已發貨",
    "order_status_canceled": "已取消",
    "order_status_unpaid": "未支付",
    "device_not_found": "設備不存在",
    "device_disable_can_not_set_warranty": "當前設備無效，無法設置延保",
    "device_was_warranty": "當前設備已延保",
    "device_not_support_warranty": "當前設備不支持延保"
  },
  "create": {
    "address": {
      "fail": "添加地址失敗"
    }
  },
  "activation": {
    "email": {
      "expire": "郵件已失效"
    }
  },
  "select_country": {
    "title": "Select Your Country/Region"
  },
  "old-invite-new": {
    "myRewardsTitle": "My Rewards",
    "myRewardsSubTitle": "Manage and use rewards that have been claimed here. For expired and used rewards, please check them in My Wallet.",
    "myRewardsNoData": "Invite your friends to subscribe or purchase, and claim your rewards on the dashboard below.",
    "viewMore": "View More",
    "myReferralsTitle": "My Referrals",
    "myReferralsSubTitle": "Track the referral progress and claim the rewards. Click the reward for details.",
    "myReferralsNoData": "Invite your friends to subscribe or make a purchase, then claim your rewards here.",
    "claim": "Claim",
    "claimed": "Claimed",
    "expired": "Expired",

    // 补充
    "myReferral": "My Referral",
    "enterEmail": "Enter Your Email Address",
    "invalidEmail": "Invalid Email Address",
    "messageSent": "Message Sent",
    "copy": "COPY",
    "copied": "COPIED",
    "copiedSuccess": "COPIED SUCCESS!",
    "friendEmail": "Enter Your Friend's Email",
    "referee": "Referee",
    "referralType": "Referral Type",
    "rewards": "Rewards",
    "status": "Status",
    "instagramCopySuccessTip": "Referral Link has copied, paste and share to Instagram",
  }
}
