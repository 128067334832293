import router from './router'
import store from './store';
import {addUrlParam} from "./utils/helpers/utils"
import { 
    handleSetLocal,
    handleParamsJkyAppLogin,
    handleThirdLoginParams,
    whiteList, 
    handleThirdAutoLogin,
    handleParamsUrlUpdate,
    noUserPath,
    setShopConfig
} from "./utils/helpers/app"
import { getUserToken } from "./utils/helpers/auth"
import { isThirdLogin, isTokenLogin, isJackeryTokenLogin } from "./utils/helpers/utils"

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const handleBeforeAction = async () => {
    /* --- 入参处理 - start --- */

    // 处理参数携带jkyAppLogin 非jackery主域快捷登录兼容
    try {
        handleParamsJkyAppLogin();
    } catch (error) {
        console.error("permission: jkyAppLogin params init error");
    }
    /* --- 入参处理 - end --- */

    // 初始化设置站点信息 国家地区语言等
    // 通过store中language.current.name简单判断是否已经初始化
    if(!store.state.app.language.current.name) {
        try {
            await handleSetLocal()
            setShopConfig()
        } catch (error) {
            console.error("permission: area & language init error");
        }
    }
}

router.beforeEach(async (to, from, next) => {

    NProgress.start()
    await handleBeforeAction()

    // FIX: 处理三方登录参数自动登录
    if(isThirdLogin()) {
        if(isThirdLogin().emailExist === 1) {
            await store.dispatch('app/thirdLogin', {
                ...isThirdLogin() 
             })
        } else { // 需要绑定邮箱 - login页面处理
            if(to.path !== "/login") {
                next({path: "/login", query: {...to.query, return_url: location.origin + location.pathname}})
            } else {
                next()
            }
        }
    }

    // FIX: 处理携带参数：Authorization进入自动登录
    if(isTokenLogin()) {
        await store.dispatch('app/loginByAuth', {token: isTokenLogin()})
        next(false)
    }

    // FIX: 处理携带参数：jackery_token进入自动登录
    // if(isJackeryTokenLogin()) {}

    if(getUserToken()) {
        if(store.state.permissionPlus.addRoutes.length === 0) {
            const accessRoutes = await store.dispatch('permissionPlus/generateRoutes')
            const baseRoutes = {...store.state.permissionPlus.baseRoutes};
            accessRoutes.forEach(item => {
                baseRoutes.children.push(item)
            })
            router.addRoute(baseRoutes)
            next({ ...to, replace: true })
            NProgress.done()
        } else {
            next()
            NProgress.done()
        }
    } else { // 未登录
        const whiteRoutes = whiteList() || []
        if(whiteRoutes.indexOf(to.path) === -1) {
            let _path = to.path.startsWith('/') ? `${location.origin}${to.path}` : `${location.origin}/${to.path}`
            next(`/login?redirect_url=${_path}`)
            NProgress.done()
        } else {
            next()
            NProgress.done()
        }
    }
})

router.afterEach(async () => {
    if(getUserToken() && noUserPath()) {
        // 通过store中userInfo.uid简单判断是否已经初始化登录用户的用户信息
        const hasUserInfo = store.state.app.userInfo.uid
        if(!hasUserInfo) {
            try {
                await store.dispatch('app/getUserInfo');
            } catch (error) {
                console.error("permission afterEach: user info init error!")
            }
        }
    }

    /* --- 入参处理 - start --- */

    // 处理参数携带 更新url - 删除指定参数
    try {
        handleParamsUrlUpdate("cr");
        handleParamsUrlUpdate("jackery_token");
        handleParamsUrlUpdate("Authorization");
    } catch (error) {
        console.log("permission afterEach: update params url error");
    }

    try {
        await handleThirdLoginParams(); // 在前置拦截器无法获取当前路由信息，所以在该处处理
    } catch (error) {
        console.error("permission afterEach: handleThirdLoginParams params init error");
    }
    /* --- 入参处理 - end --- */
})