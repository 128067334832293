import {cookie} from "../../utils/common"
export const CR_KEY = "_jk_cr";
export const DOMAIN_KEY = "_jk_domain";
export const SHOP_ID_KEY = "_jk_shop_id";

export const USER_KEY = "_jk_user";
export const ID_KEY = "_jk_id";

/**
 * 从本地存储中获取当前站点信息
 *
 * @param {string} param - URLSearchParams key
 */
 export function getCurrentStore() {
    return {
        shop_id: cookie.get(SHOP_ID_KEY),   // 店铺id
        domain: cookie.get(DOMAIN_KEY),     // 店铺地址
        cr: cookie.get(CR_KEY),             // 店铺国家简码
    }
}

/**
 * 判断用户是否登录 - user token
 */
 export function getUserToken() {
    return cookie.get(USER_KEY);
}

/**
 * 获取ID token
 */
 export function getIDToken() {
    return cookie.get(ID_KEY);
}

/**
 * 清除用户token信息
 */
 export function removeToken() {
     // 用户登录, 如果是账号密码登录，登陆前先清除旧token缓存, 那个死大顺非要验证旧的token(就逻辑保留)
     localStorage.removeItem("JACKERY_ID_TOKEN");

     cookie.erase(USER_KEY);
     cookie.erase(ID_KEY);
}

/**
 * 保存token信息(id & user)
 */
 export function setToken(params = {}) {
    params[USER_KEY] &&  cookie.set([USER_KEY], params[USER_KEY]);
    params[ID_KEY] &&  cookie.set([ID_KEY], params[ID_KEY]);
}