/**
 * ID部分接口，个人信息，获取验证码，公钥，S3等
 * 创建者: garfield
 * 创建日期: 2021-12-16
 */

import { post, get } from './http';
import { parseUrl, cookie } from "@u/common";
import JSEncrypt from "jsencrypt"

// 获取公钥并加密
export const getPublicKeyAndEncode = async (params) => {
  let { email, password, method } = params;
  let res = await get(`/account/msgAction/getPublickey`, { params: {email, method} });
  let pubKey = res.publickey;
  let encryptor = new JSEncrypt();
  encryptor.setPublicKey(pubKey); // 设置公钥

  // 密码可能是多个，对内容进行加密，回传对应的公钥和method
  if (password.constructor === Array) {
    let arr = [];
    for (let i in password) { arr.push(encryptor.encrypt(password[i])) }
    return Promise.resolve({ password: arr, method, pubKey })
  }
  return Promise.resolve({ password: encryptor.encrypt(password), method, pubKey });
}

// 注册, 并同时收集来源信息
export const registerUser = async (params) => {
  params.verificationCode = params.code;
  delete params.secPassword;
  let { password, method, pubKey } = await getPublicKeyAndEncode({email: params.email, password: params.password, method: 'register'});
  params.password = password;
  let ref = sessionStorage.getItem("JACKERY_ID_REFERRER");
  if (ref) {
    let arr = parseUrl(ref);
    Object.assign(params, arr);
  }
  return post(`/account/accountAction/register?method=${method}&pubKey=${pubKey}`, params);
};

// 通过email获取code
export const getEmailCode = (params) => {
  sessionStorage.setItem("TEMP_VERIFY", params.email);
  return get(`/account/msgAction/sendEmailByEmail/`, {params});
}

// 账户信息修改注册邮箱第二步
export const getEmailCodeNext = (params) => {
  sessionStorage.setItem("TEMP_VERIFY", params.email);
  return get(`/account/msgAction/sendModifyEmailByEmail`, {params})
}

// 通过jackery id获取code
export const getEmailCodeByJid = (params) => {
  sessionStorage.setItem("TEMP_VERIFY", params.userName);
  return get(`/account/msgAction/sendEmailByUserName`, {params})
}

// 通过UID获取验证码
export const sendEmailByUid = (params) => {
  sessionStorage.setItem("TEMP_VERIFY", params.uid);
  return get(`/account/msgAction/sendEmailByUid`, {params})
}

// 用户登录, 如果是账号密码登录，登陆前先清除旧token缓存, 那个死大顺非要验证旧的token
export const userLogin = async (params) => {
  if (params.email && params.password) {
    localStorage.removeItem("JACKERY_ID_TOKEN");
    cookie.erase("_jk_id");
    cookie.erase("_jk_user");
    cookie.erase("_jk_user_id");
    cookie.erase("_jk_user_email");
    let { password, method, pubKey } = await getPublicKeyAndEncode({email: params.email, password: params.password, method: 'login'});
    params.password = password;
    return post(`/account/accountAction/login?method=${method}&pubKey=${pubKey}`, params);
  }

  return post(`/account/accountAction/login`, params);
}
// 获取全面的用户信息
export const userInfo = (params) => {
  return get(`/account/accountProfileAction/findAccountProfile`, {params});
}
// 获取第三方账号信息
export const userThirdInfo = (params) => {
  return get(`/account/accountThirdAction/findByUid`, {params});
}

// 获取s3信息
export const getS3Config = (params) => {
  return get(`/account/accountProfileAction/getSts?site=jackery`, { params });
}
// 上传文件到s3
export const uploadToS3 = (file, s3Config, fileName) => {
  return new Promise((resolve, reject) => {
    let region = s3Config.regionName;
    let Bucket = s3Config.bucketName;
    let filePath = s3Config.savePath + fileName;
    let { accessKeyId, secretAccessKey, sessionToken } = s3Config.credentials;

    // 分片上传, 每5M分片, 5 * 1024 * 1024
    let queueSize = Math.ceil(file.size / 5242880);
    let s3Param = { Bucket, Key: filePath, Body: file, ContentType: file.type };
    let s3 = new window.AWS.S3({ accessKeyId, secretAccessKey, sessionToken, region });
    let upload = s3.upload(s3Param, {
      partSize: file.size < 5242880 ? 5242880 : file.size, queueSize,
      httpOptions: { connectTimeout: 60 * 1000 * 10, timeout: 60 * 1000 * 10 }
    }).on("httpUploadProgress", function (e) {
      //let percent = parseInt(e.loaded, 10) / parseInt(e.total, 10);
      //console.log(percent.toFixed(2) * 100);
    });
    upload.send(function (err, data) {
      // this.$message.success(msg);
      if (err) {
        console.log("upload send Error:", err.code, err.message);
        reject(err)
      } else {
        resolve(data)
      }
    })
  })
}

// 修改用户信息
export const changeUserInfo = (params) => {
  return post(`/account/accountProfileAction/modify`, params)
}
// 修改用户信息
export const modifyAccountInfo = (params) => {
  return post(`/account/accountAction/modify`, params)
}

// 用户密码修改
export const passwordModify = async (params) => {
  delete params.secPassword;
  let { password, method, pubKey } = await getPublicKeyAndEncode({email: params.email || params.userName, password: params.password, method: 'modifyPassword'});
  params.password = password;
  return post(`/account/accountAction/modifyPassword?method=${method}&pubKey=${pubKey}`, params)
}

// 账户页面修改密码
export const ProfilePasswordModify = async (params) => {
  let url = '';
  if (params.oldPassword) {
    let { password, method, pubKey } = await getPublicKeyAndEncode({ email: params.email, password: [params.oldPassword, params.password], method: 'profileModifyPassword' });
    params.oldPassword = password[0];
    params.password = password[1]
    params.method = method;
    params.pubKey = pubKey;
    url = `/account/accountAction/profileModifyPassword?method=${method}&pubKey=${pubKey}`
  } else {
    let { password, method, pubKey } = await getPublicKeyAndEncode({email: params.email, password: params.password, method: 'profileModifyPassword'});
    params.password = password;
    params.method = method;
    params.pubKey = pubKey;
    url = `/account/accountAction/profileModifyPassword?method=${method}&pubKey=${pubKey}`
  }
  return post(url, params)
}

// 用户注册邮箱修改
export const registerEmailModify = (params) => {
  return post(`/account/accountAction/modify`, params)
}

// 找回密码，根据邮箱校验验证码
// rule: /account/msgAction/checkVerificationCode/{uid}/{type}/{verificationCode}
export const verifyCodeForEmail = (params) => {
  return get(`/account/msgAction/checkVerificationCodeByEmail/${params.email}/3/${params.code}`)
}

// 国际区号
export const getInterCode = (params) => {
  return get(`/account/dicAction/findIntTelCode`, {params})
}

// 机器人验证
export const checkMachine = (params) => {
  let method = location.pathname.replace(/\//g, '');
  return get(`/account/msgAction/checkImageVerificationCode/${params.email}/${params.verificationCode}?method=${method}`)
}

// 第三方渠道
export const channelThirdLogin = (params) => {
  return get('/account/accountThirdAction/findAccountThirdChannelsByState', {params})
}

// 第三方渠道创建登录账号, 如果不存在邮箱时, 需要绑定邮箱
// /account/oauthAction/createLoginAccount/{source}
export const createThirdLogin = (params) => {
  // let e_v = params.email ? `&email=${params.email}&verificationCode=${params.verificationCode}` : "";
  return get(`/account/oauthAction/createLoginAccount/${params.source}`, {params})
}

// 特定的，第三方渠道绑定邮箱时需要用到的发送验证码和相关邮件
export const ThirdLoginBindEmail = (params) => {
  sessionStorage.setItem("TEMP_VERIFY", params.email);
  return get(`/account/msgAction/sendEmailByTypeEmail`, {params})
}

// 获取国家区域
export const getRegion = (params) => {
  return get('/account/dicAction/findRegionByIso', {params})
}

export const logout = (params) => {
  return get('/account/accountAction/logout', {params})
}

// 获取国家地区
export const findRegionByLang = (params) => {
  return get('/account/dicAction/findRegionByLang', {params})
}

