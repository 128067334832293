import { createStore } from 'vuex';
import { checkMachine } from "@a/account";
import { shopifyLogout } from "@a/userCommon";
import { cookie } from "@u/common";
import modules from './modules/';
import i18n from "../lang";
import router from "../router";
const { locale } = i18n.global;
import getters from './getters'

export default createStore({
  state: {
    publicKey: '',
    language: {
      "en-US": "United States (English)", "ja-JP": "日本 (日本語)",
      "zh-CN": "中国大陆 (简体中文)", "zh-HK": "中國香港/澳門/臺灣 (繁體中文)"
    },
    antLanguage: null,
    userData: { id: '', email: '' },
    machine: {
      visible: false, verificationCode: "", email: ""
    },
    personal: [
      '/index',
      '/index/guide'
    ]
  },
  getters,
  // getters: {
  //   GET_USER: state => state.userData,
  //   GET_PUBLIC_KEY: state => state.publicKey,
  //   GET_LANGUAGE: state => state.language,
  //   GET_MACHINE: state => state.machine,
  //   GET_ANT_LANG: state => state.antLanguage
  // },
  mutations: {
    changeUser(state, data) {
      state.userData = Object.assign(state.userData, data);
      data && localStorage.setItem('JACKERY_ID_USER', JSON.stringify(state.userData))
    },
    changePublic(state, data) {
      state.publicKey = data
    },
    changeMachineVerify(state, data) {
      state.machine = data
    },
    changeAntLanguage(state, data) {
      state.antLanguage = data
    }
  },
  actions: {
    async USER_LOGIN_OUT({ commit }, from) {
      commit('permission/CLEAR_PERMISSION');
      commit('changePublic', '');
      commit('changeUser', '');

      localStorage.removeItem("JACKERY_USER_COUNTRY");
      localStorage.removeItem("JACKERY_ID_USER");
      localStorage.removeItem("JACKERY_ID_PER");
      sessionStorage.removeItem("JACKERY_ID_REF");

      cookie.erase("_jk_user_id");
      cookie.erase("_jk_user_email");

      localStorage.removeItem("JACKERY_ID_TOKEN");
      cookie.erase("_jk_id");

      let token = cookie.get("_jk_user") || localStorage.getItem("JACKERY_USER_TOKEN");

      return token ? new Promise((resolve) => {
        shopifyLogout().finally(() => {
          if (from !== 'membership') {
            router.push({ path: '/login', query: { active: 'logout' } })
          }
          localStorage.removeItem("JACKERY_USER_TOKEN");
          cookie.erase("_jk_user");

          resolve()
        })
      }) : new Promise((resolve) => {
        localStorage.removeItem("JACKERY_USER_TOKEN");
        cookie.erase("_jk_user");

        resolve()
      })
    },
    async CHANGE_LANGUAGE({ commit }, { lang, shop_id, cr, domain, }) {
      localStorage.removeItem("JACKERY_USER_COUNTRY");
      localStorage.removeItem("JACKERY_ID_INTERCODE");
      localStorage.removeItem("JACKERY_ID_INTERCODE_DEFAULT");
      sessionStorage.removeItem("TEMP_QUESTION");
      i18n.locale = lang || "en-US";

      localStorage.setItem("JACKERY_ID_LANG", lang);
      localStorage.setItem("JACKERY_ID_SHOP_ID", shop_id);
      localStorage.setItem("JACKERY_ID_DOMAIN", domain);
      localStorage.setItem("JACKERY_ID_CR", cr);
      cookie.set('_jk_lang', lang);
      cookie.set('_jk_shop_id', shop_id);
      cookie.set('_jk_cr', cr);

      return Promise.resolve()
    },
    async MACHINE_VERIFY({ commit }) {
      return new Promise((resolve, reject) => {
        checkMachine(this.state.machine).then(() => {
          commit('changeMachineVerify', { visible: false, verificationCode: "", email: "" })
          resolve()
        }).catch(() => {
          reject()
        })
      })
    }
  },
  modules
});
