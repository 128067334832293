<template>
  <a-config-provider :locale="antLang">
    <router-view v-if="isRouterAlive"></router-view>

    <a-modal
      wrap-class-name="mobile-full-modal machine"
      v-model:visible="machine().visible"
      :maskClosable="false"
      :centered="true"
      :zIndex="1001"
      :title="t('tips.requestFrequently')"
      :confirm-loading="state.loading"
      :ok-text="t('operate.confirm')"
      :cancel-text="t('operate.cancel')"
      @ok="handleOK"
    >
      <p>{{ t('login.enter_code') }}</p>
      <div class="content">
        <a-input
          size="large"
          :maxlength="32"
          v-model:value="machine().verificationCode"
          :placeholder="t('tips.placeholder')"
        ></a-input>
        <span class="codeImg">
        <img :src="state.img" alt="" />
        <reload-outlined class="trigger" @click.prevent="reload" />
      </span>
      </div>
    </a-modal>
  </a-config-provider>
</template>

<script setup>
  import { ReloadOutlined } from '@ant-design/icons-vue';
  import spin from "@c/Spin.vue";

  import { h } from "vue";
  import { useStore } from 'vuex';
  import { Spin, message } from "ant-design-vue";
  import { shopLists, shopListsApi, } from "@a/userCommon";
  import { channelThirdLogin } from "@a/account";
  import { handleLang, cookie, getUrlParam } from "@u/common";
  import { setAntDvLocale } from "./lang"

  const { t } = useI18n();
  const v18n = useI18n();
  const store = useStore();
  const route = useRoute();

  const machine = () => store.getters.GET_MACHINE;
  const antLang = computed(() => setAntDvLocale(store.getters.language.currentLang));

  const state = reactive({ verify: '', img: '', loading: false });
  const isRouterAlive = ref(true);

  // 已登录情况下, 要以登陆后的店铺数据为主
  const verifyStore = ref(false);

  // setting global spin loading
  Spin.setDefaultIndicator({
    indicator: h(spin)
  });
  // setting global message position
  message.config({top: '20px'});

  const handleOK = () => {
    state.loading = true;
    store.dispatch('MACHINE_VERIFY').then(() => {
      state.loading = false
    }).catch(() => {
      state.loading = false
    })
  }

  const refresh = () => {
    message.loading({ content: t('tips.loading'), key: 'appRefresh' });
    isRouterAlive.value = false;
    nextTick(() => {
      message.success({ content: t('tips.refreshed'), key: 'appRefresh', duration: 3 });
      isRouterAlive.value = true;
      console.log(t('tips.refreshed'))
    })
  };

  const reload = () => {
    let method = location.pathname.replace(/\//g, '');
    state.img = `${import.meta.env.VITE_MACHINE_URL}/account/servlet/drawImage?email=${sessionStorage.getItem('TEMP_VERIFY')}&t=${new Date().getTime()}&method=${method}`
  }

  // extract global function
  provide('APP/refresh', refresh);
  provide('APP/verifyStore', verifyStore);

  watch(() => store.state.machine.visible, (val) => {
    val && reload()
  })
</script>
