import { dynamicRoutes, constantRoutes } from '../../router/config'
import { getUserToken } from "../../utils/helpers/auth"

/**
 * 通过路由meta信息中requireAuth判断是否需要登录
 * @param route
 */
function hasPermission(route) {
    if (route.meta && route.meta.requireAuth) {
        return !!getUserToken()
    } else {
        return true
    }
}

/**
 * 递归过滤权限路由
 * @param routes asyncRoutes
 */
export function filterAsyncRoutes(routes) {
    const res = []

    routes.forEach(route => {
        const tmp = { ...route }
        if (hasPermission(tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children)
            }
            res.push(tmp)
        }
    })

    return res
}



const state = {
  routes: [],
  addRoutes: [],

  /**
     * id权限路由layout - 所有权限路由挂挂载在该子集中
     * 我草了，什么设定啊！！！
     * @param route
  */
  baseRoutes: {
    path: '/', 
    name: 'home', 
    redirect: '/account/overview',
    component: () => import('../../views/home.vue'), 
    meta: { requireAuth: true },
    children: []
  }
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }) {
    return new Promise(resolve => {
      let accessedRoutes
      if (!!getUserToken()) {
        accessedRoutes = dynamicRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(dynamicRoutes)
      }
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
  