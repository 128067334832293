import { createRouter, createWebHistory } from 'vue-router';

const m = import.meta.glob('../views/**/*.vue');
const load = (path) => m[`../views/${path}.vue`];

/**
 * constantRoutes
 * 不需要身份认证 所有情况都可以访问的页面
 */
export const constantRoutes = [
  {
    path: '/404', name: 'error404', component: load('404'),
    meta: {requireAuth: false, title: 'router.404', description: 'description.404'}
  },
  {
    path: '/500', name: '500', component: load('500'),
    meta: {requireAuth: false, title: 'router.500', description: 'description.500'}
  },
  {
    path: '/login', name: 'login', component: load('login'),
    meta: {requireAuth: false, title: 'router.login', description: 'description.login'}
  },
  {
    path: '/register', name: 'register', component: load('register'),
    meta: {requireAuth: false, title: 'router.register', description: 'description.register'}
  },
  {
    path: '/reset', name: 'reset', component: load('reset'),
    meta: {requireAuth: false, title: 'router.reset', description: 'description.reset'}
  },
  {
    path: '/activate', name: 'activate', component: load('activate'),
    meta: {requireAuth: false, title: 'router.activate', description: 'description.activate'}
  },
  {
    path: '/identification', name: 'identification', component: load('identification'),
    meta: {requireAuth: false, title: 'router.activate', description: 'description.activate'}
  },
  {
    path: '/policies', name: 'policies', component: load('policies/layout'),
    meta: { requireAuth: false, },
    children: [
      {
        path: 'terms-of-service', name: 'terms-of-service', component: load('policies/terms-of-service'),
        meta: { requireAuth: false, title: 'router.termsOfService', description: 'description.termsOfService' },
      },
      {
        path: 'privacy-policy', name: 'privacy-policy', component: load('policies/privacy-policy'),
        meta: { requireAuth: false, title: 'router.privacyPolicy', description: 'description.privacyPolicy' },
      }
    ]
  },
  {
    path: '/index', name: 'layout', component: load('index/layout'),
    meta: { requireAuth: false, },
    children: [
      {
        path: '', name: 'index', component: load('index/index'),
        meta: { requireAuth: false, title: 'router.memberIndex', description: 'description.memberIndex' },
      },
      {
        path: 'guide', name: 'guide', component: load('index/guide'),
        meta: { requireAuth: false, title: 'router.memberGuide', description: 'description.memberGuide' },
      }
    ]
  },
  {
    path: '/', name: 'home', component: load('home'),
    meta: { requireAuth: true, title: 'router.home', description: 'description.home' },
    children: []
  }
]


const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from) {
    return { x: 0, y: 0 }
  },
  routes: constantRoutes
});

/** 权限路由 */
export const dynamicRoutes = [
  {
    path: '/id', 
    name: 'id',
    redirect: '/account/overview'
  },
  {
    path: '/account/overview', 
    name: 'accountOverview', 
    component: load('account/overview'),
    meta: {requireAuth: true, title: 'router.accountOverview', description: 'description.accountOverview'}
  },
  {
    path: '/account/myProfile', name: 'accountProfile', component: load('account/myProfile'),
    meta: {requireAuth: true, title: 'router.myProfile', description: 'description.myProfile'}
  },
  {
    path: '/account/order', name: 'accountOrder', component: load('account/order/index'),
    meta: {requireAuth: true, title: 'router.myOrders', description: 'description.myOrders'}
  },
  {
    path: '/account/address', name: 'accountAddress', component: load('account/address/index'),
    meta: {requireAuth: true, title: 'router.addressBook', description: 'description.addressBook'}
  },
  {
    path: '/account/wishlist', name: 'accountWishlist', component: load('account/wishlist'),
    meta: {requireAuth: true, title: 'router.myWishlist', description: 'description.myWishlist'}
  },
  {
    path: '/account/setting', name: 'accountSettings', component: load('account/setting'),
    meta: {requireAuth: true, title: 'router.accountSettings', description: 'description.accountSettings'}
  },
  {
    path: '/account/devices', name: 'accountDevices', component: load('account/devices'),
    meta: {requireAuth: true, title: 'router.myDevices', description: 'description.myDevices'}
  },
  {
    path: '/account/referral', name: 'accountReferral', component: load('account/referral'),
    meta: {requireAuth: true, title: 'router.myReferral', description: 'description.myReferral'}
  },
  {
    path: '/account/return', name: 'accountReturn', component: load('account/return'),
    meta: {requireAuth: true, title: 'router.myReturn', description: 'description.myReturn'}
  },
  {
    path: '/account/wallet', name: 'accountWallet', component: load('account/wallet'),
    meta: {requireAuth: true, title: 'router.myWallet', description: 'description.myWallet'}
  },
  {
    path: '/account/service', name: 'accountService', component: load('account/service'),
    meta: {requireAuth: true, title: 'router.myService', description: 'description.myService'}
  },
  {
    path: '/account/myJackeryLife', name: 'accountJackeryLife', component: load('account/jackeryLife'),
    meta: {requireAuth: true, title: 'router.myJackeryLife', description: 'description.myJackeryLife'}
  },
  {
    path: '/account/trade-in', name: 'accountTradeIn', component: load('account/trade-in'),
    meta: {requireAuth: true, title: 'router.myTradeIn', description: 'description.myTradeIn'}
  },
  { path: '/:catchAll(.*)', redirect: '/404', hidden: true }
];

/** 初始路由 */
export default router;