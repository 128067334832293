/**
 * 订单部分接口
 * 创建者: garfield
 * 创建日期: 2021-12-31
 */

import { uPost, uGet, post, get } from './http';
import { getPublicKeyAndEncode } from "./account"

// 如果是从shopify来登录, 需要登录ID系统拿到token后再返回shopify
// 其中要确认一下token是否包含bearer, 如果包含要去掉, java大顺坑货大王
export const shopifyLogin = (params) => {
  return uPost('/v1/user/login', params);
}

export const register = async(params) => {
  let { password, method, pubKey } = await getPublicKeyAndEncode({email: params.email, password: params.password, method: 'register'});
  params.password = password;

  return uPost(`/v1/user/register`, params);
}

// 检查登录
export const shopifyUserCheck = (params) => {
  return uGet('/v1/user/check', {params});
}

// 前往购物
export const toShopify = (params) => {
  return uGet('/v1/user/go-shopping', {params});
}

// shopify 站点列表  ---- 弃用
export const shopifyWebsite = (params) => {
  return uGet('/v1/region/lists', {params});
}

// shopify 站点列表, 免验证 ---- 弃用
export const shopifyRegionList = (params) => {
  return uGet('/v1/region/lists-not-auth', {params});
}

// 切换站点信息
export const shopifyChangeRegion = (params) => {
  return uGet('/v1/user/change-region', {params});
}

// 登出
export const shopifyLogout = (params) => {
  return uGet('/v1/user/logout', {params});
}

// 注册成功同步shopify 接口
export const shopifySyncUser = (params) => {
  return uPost('/v1/user/sync-user-shopify', params);
}

// 激活游客
export const shopifyGuestActivate = (params) => {
  return uPost('/v1/user/customer-activate', params);
}

// 注册激活
export const shopifyRegisterActivate = (params) => {
  return uPost('/v1/user/customer-register', params);
}

// 十周年同步用户信息接口
export const shopifyTenthAnniversary = (params) => {
  return uPost('/v1/user/act-ten-year', params);
}

// 获取站点接口-免验证
export const shopLists = (params) => {
  return uGet('/v1/region/shop-lists-na', {params});
}

// 获取站点接口
export const shopListsApi = (params) => {
  return uGet('/v1/region/shop-lists', {params});
}

// 获取用户是否完成大额订单
export const getUserFirstLargeOrder = () => {
  return uGet('/v1/user/getUserFirstLargeOrder');
}

// 获取用户注册积分权益
export const getRegisterInterests = (params) => {
  return uGet('/v1/point-boot-campaign-customer/get-register-interests', {params});
}
