import { getLanguage } from '../../lang/index';
import { getCurrentStore, setHtmlPageLang, backToPath } from "../../utils/helpers/utils"
import {getKeyByValue, COUNTRY_LANG} from "../../utils/helpers/siteData"
import { getUserToken, setToken, USER_KEY, ID_KEY, removeToken } from "../../utils/helpers/auth"
import { getRedirectUrl, whiteList } from "../../utils/helpers/app"
import { cookie } from "../../utils/common"
import { notification, message } from 'ant-design-vue';

import { 
    shopLists, 
    shopifyLogin,
    shopifySyncUser,
    shopifyLogout,
    register as uRegister
} from "@a/userCommon";
import {
    userInfo, 
    userThirdInfo, 
    changeUserInfo,
    userLogin,
    createThirdLogin
} from "@a/account";
import router from "../../router"

/**
 * id系统登录后执行：登录shopify
 */
const loginShopify = async (params, idToken) => {
    await shopifyLogin({
        return_url: params.return_url || getRedirectUrl(),
        shop_id: state.language.current.shop_id,
        token: params.token.replace("bearer ", "")
    }).then(response => {
        setToken({
            [USER_KEY]: response.jackery_token,
            [ID_KEY]: idToken
        })
        // mutilpass登录后自动重定向到 return_url
        window.location.href = response.redirect_url;
    }).catch(e => {
        console.error(`login error: ${e}`);
        backToPath("/login");
    })
}

const state = {
  currentStore: getCurrentStore(),
  language: {
    list: [],
    current: getCurrentStore(), // shop_id、domain、cr
    currentLang: getLanguage(),
    lists: [], // 所有国家区域站点信息
  },

  // 用户信息
  userInfo: {},

}

const mutations = {
  SET_LANGUAGE_INFO: (state, { lang, current, lists }) => {
    state.language.currentLang = lang;
    cookie.set('_jk_lang', lang);

    state.language.current = {...current};
    cookie.set('_jk_shop_id', current.shop_id);
    cookie.set('_jk_cr', current.cr);

    state.language.lists = {...lists};

    setHtmlPageLang(lang.split("-")[0])
  },
  SET_CURRENT_STORE: (state, config = {}) => {
    const {shop_id, domain, cr} = config;

    if(shop_id) {
        state.currentStore.shop_id = shop_id;
        cookie.set('_jk_shop_id', shop_id);
    }

    if(domain) {
        state.currentStore.domain = domain;
        cookie.set('_jk_domain', domain);
    }

    if(cr) {
        state.currentStore.cr = cr;
        cookie.set('_jk_cr', cr);
    }
  },
  SET_USER_INFO: (state, info = {}) => {
    state.userInfo = {...info};
  }
}
  
const actions = {
    async setLanguage({ commit }, language) {
        // 根据多语言获取国家简码 cr
        const CR = getKeyByValue(COUNTRY_LANG, language);
        if(!CR) return;

        await shopLists({cr: CR}).then(res => {
            const {current, lists} = res

            commit('SET_LANGUAGE_INFO', {
                lang: language,
                current,
                lists
            });
        })
    },
    setCurrentStore({ commit }, config) {
        commit("SET_CURRENT_STORE", config)
    },
    async getUserInfo({ commit }, info) { // 获取已登录用户信息
        console.log("---获取已登录用户信息---")
        await userInfo().then(async res => {
            let user_info = {}
            res.intTelCode = res.intTelCode || undefined;
            res.birthday = res.birthday || 0;
            user_info = {...res}

            await userThirdInfo({ uid: res.uid }).then(r => {
                if (r.length) {
                  let channels = {};
                  r.forEach(item => {
                    channels[item.channels.toLowerCase()] = item
                  })
                  user_info.channels = channels;
                }
                commit('SET_USER_INFO', {...user_info});
                console.log(user_info)
            })
        })
    },
    async setUserInfo(context, info) { // 修改用户信息
        console.log("---更新已登录用户信息---")
        await changeUserInfo({
            id: state.userInfo.id,
            uid: state.userInfo.uid,
            ...info
        }).then(async res => {
            context.dispatch("getUserInfo")
        })
    },

    /**
     * 处理外链进入id系统，携带参数 Authorization:
     * 自动保存替换TOKEN（_jk_id）
     * @param {string} token - idtoken
     * @param {return_url?} return_url - 登录成功后回跳地址
     */
    loginByAuth({ commit }, params) {
        return new Promise(async(resolve, reject) => {
            shopifyLogin({
                return_url: params.return_url || getRedirectUrl(),
                shop_id: state.language.current.shop_id,
                token: params.token
            }).then(response => {
                setToken({
                    [USER_KEY]: response.jackery_token,
                    [ID_KEY]: params.token
                })
                window.location.href = response.redirect_url;
            }).catch(error => {
                console.error(`login error: ${error}`);
                setTimeout(() => {
                    let link =`${state.language.current.domain}/pages/login?return_url=${location.origin}/account/myProfile`
                    window.location.href = link
                }, 3000);
                reject(error)
            })
        })
    },

    /**
     * 账号密码登录 
     * @param {activate} boolean - （shopifyLogin） or （shopifySyncUser）
     * @param {uparams?} uparams - shopifySyncUser情况下请求参数
     */
    loginByPassword({ commit }, params) { // 正常账号密码登录
        console.log("params---", params)
        return new Promise(async(resolve, reject) => {
            userLogin({...params}).then(res => {
                if(params.activate === true) { // FIX：shopify user first need activated
                    shopifySyncUser({
                        return_url: params.return_url || getRedirectUrl(),
                        ...params.uparams, 
                        shop_id: state.language.current.shop_id, 
                        shopify_shop_id: state.language.current.shopify_shop_id
                    }).then(r => {
                        setToken({
                            [USER_KEY]: r.jackery_token,
                            [ID_KEY]: res.token.replace("bearer ", "")
                        })
                        window.location = r.redirect_url
                    }).catch(e => {
                        console.error(e)
                        reject()
                    })
                } else { // 默认登录流程
                    shopifyLogin({
                        return_url: params.return_url || getRedirectUrl(),
                        shop_id: state.language.current.shop_id,
                        token: res.token.replace("bearer ", "")
                    }).then(response => {
                        setToken({
                            [USER_KEY]: response.jackery_token,
                            [ID_KEY]: res.token.replace("bearer ", "")
                        })
                        window.location.href = response.redirect_url;
                    }).catch(error => {
                        console.error(`login error: ${error}`);
                        reject(error)
                    })   
                }
            }).catch(e => {
                reject(e)
            })
        })
    },

    thirdLogin(context, params) { // 三方登录 uid 、emailExist、source、belongBrandChannel
        const {emailExist, belongBrandChannel, source, uid, email, verificationCode} = params;
        let _params = {}
        if(Number(emailExist) === 1) {
            _params = {
                belongBrandChannel, source, uid
            }
        } else { // 未绑定邮箱处理
            _params = {
                belongBrandChannel, source, uid, verificationCode, email
            }
        }

        return new Promise(async(resolve, reject) => {
            createThirdLogin({..._params}).then(async res => {
                await context.dispatch("loginByAuth", {token: res.Authorization})
            }).catch(e => {
                console.error(`thirdLogin error: ${e}`);
                setTimeout(() => {
                    window.location.href = location.origin + '/login?redirect_url=' + getRedirectUrl()
                }, 3000);
            })
        })
    },

    register({ commit }, params) { // 用户注册
        return new Promise(function(resolve, reject) {
            uRegister({...params}).then(res => {
                setToken({
                    [USER_KEY]: res.jackery_token,
                    [ID_KEY]: res.id_token
                })
                resolve(res)
            }).catch(e => {
                console.error(e)
                reject(e)
            })
        })
    },

    logout({commit}) {
        if(!getUserToken()) return // 避免请求死循环
        const whiteRoutes = whiteList() || []

        console.log(router.currentRoute.value)

        return new Promise((resolve, reject) => {
            shopifyLogout().then(() => {
                removeToken()
                commit("SET_USER_INFO", {})
                const current = router.currentRoute.value.path || ""
                if(current.indexOf("/index") !== -1) { // 需要登录的页面退回login
                    location.reload()
                    resolve(false)
                }
                resolve(true)

                // resolve(true)
            }).catch(e => {
                removeToken()
                commit("SET_USER_INFO", {})
                router.push({ path: '/login' })
                reject(e)
            })
        })
    } 
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}