const getters = {
    currentStore: state => state.app.currentStore,
    language: state => state.app.language,
    userInfo: state => state.app.userInfo,


    GET_USER: state => state.userData,
    GET_PUBLIC_KEY: state => state.publicKey,
    GET_LANGUAGE: state => state.language,
    GET_MACHINE: state => state.machine,
    GET_ANT_LANG: state => state.antLanguage
  }
  export default getters
  