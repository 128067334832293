import {cookie} from "../utils/common"
import {setShopConfig} from "../utils/helpers/app"
import { createI18n } from 'vue-i18n';

import store from '../store';

import zhCN from "./zh-CN";
import zhHK from "./zh-HK";
import enUS from "./en-US";
import enCA from "./en-CA";
import jaJP from "./ja-JP";
import deDE from './de-DE';
import frFR from './fr-FR';
import itIT from './it-IT';
import esES from './es-ES';
import enEU from './en-EU';
import enAU from './en-AU';
import enGB from './en-GB';
import koKR from './ko-KR';


import ant_zhCN from 'ant-design-vue/es/locale/zh_CN';
import ant_zhHK from 'ant-design-vue/es/locale/zh_HK';
import ant_enUS from 'ant-design-vue/es/locale/en_US';
import ant_jaJP from 'ant-design-vue/es/locale/ja_JP';
import ant_deDE from 'ant-design-vue/es/locale/de_DE';
import ant_frFR from 'ant-design-vue/es/locale/fr_FR';
import ant_itIT from 'ant-design-vue/es/locale/it_IT';
import ant_esES from 'ant-design-vue/es/locale/es_ES';
import ant_koKR from 'ant-design-vue/es/locale/ko_KR';


export function getLanguage() {
  const chooseLanguage = cookie.get('_jk_lang');
  if (chooseLanguage) return chooseLanguage;
  return 'en-US';
}

const i18n = createI18n({
  legacy: false,
  locale: getLanguage(),
  warnHtmlMessage: false,
  messages: {
    'zh-CN': zhCN, 'zh-HK': zhHK, 'zh-TW': zhHK,
    'en-US': enUS, 'en-GB': enGB, 'en-CA': enCA,
    'de-DE': deDE, 'ja-JP': jaJP, 'en-AU': enAU,
    'fr-FR': frFR, 'it-IT': itIT, 'es-ES': esES,
    'en-EU': enEU, 'ko-KR': koKR,
  }
})

export const setAntDvLocale = (lang) => {
  switch (lang) {
    case 'zh-CN': return ant_zhCN;
    case 'zh-HK': case 'zh-TW': return ant_zhHK;
    case 'en-US': case 'en-CA': case 'en-EU': return ant_enUS;
    case 'de-DE': return ant_deDE;
    case 'ja-JP': return ant_jaJP;
    case 'fr-FR': return ant_frFR;
    case 'it-IT': return ant_itIT;
    case 'es-ES': return ant_esES;
    case 'ko-KR': return ant_koKR;
    default: return ant_enUS;
  }
}


// 设置多语言 不刷新切换
export function setLanguage(lang) {
  console.log("---setLanguage---")

  i18n.global.locale.value = lang;
  return store.dispatch('app/setLanguage', lang).then(() => {
    setShopConfig()
  })
}

export default i18n;
