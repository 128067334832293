export default {
  "login": {
    "sign": "登录",
    "login": "登录",
    "remember": "记住我",
    "about": "关于我们",
    "illegal_email": "邮箱格式不正确",
    "illegal_password": "您的密码需6-16位，必须包含字母和数字。",
    "password_matching": "这两个密码不一致",
    "enterEmail": "请输入邮箱或Jackery ID",
    "enter_code": "请输入验证码",
    "forgot": "忘记密码?",
    "create": "创建我的Jackery ID",
    "thirdLogin": "或通过以下方式登录",
    "accountActivate": "账户激活",
    "bindEmail": "绑定您的邮箱",
    "error": "登录失败，请稍后再试"
  },
  "policies": {
    "content": "通过第三方账户登录，即表示您已经阅读并同意了我们的",
    "policy-link": "https://www.jackery.com/policies/privacy-policy",
    "policy": "隐私政策",
    "and": "和",
    "terms-link": "https://www.jackery.com/policies/terms-of-service",
    "terms": "使用条款",
    "comma": "。",
    "footer": "Jackery将会使用您选择的第三方所提供的信息进行注册或登录。"
  },
  "reset": {
    "modify": "重置密码",
    "back": "返回登录",
    "success": "新密码设置成功!",
    "referrer": "即将自动跳转回发起页面",
    "seconds": "s"
  },
  "register": {
    "title": "注册",
    "enterEmail": "请输入邮箱",
    "agree": "我已阅读并同意遵守Jackery的",
    "agree_after": "",
    "getDeals": "同意获取最新的产品、促销、服务等信息",
    "login": "立即登录",
    "success": "您的账户 {email} 注册成功!",
    "welcomeEmail": "欢迎邮件已经发送到您的邮箱。"
  },
  "header": {
    "logout": '登出',
    "userCenter": "会员中心",
    "personalCenter": "个人中心",
    "shopping": "前往店铺",
    "setting": "设置",
    "myJackery": "账号中心",
    "jackeryID": "个人中心"
  },
  "footer": {
    "cookieContent": "我们在本网站上使用 cookie，包括第三方 cookie，以便网站正常运行并分析流量、提供增强的功能、社交媒体功能以及个性化内容和广告。",
    "cookieUrl": "#",
    "learn": "了解更多",
    "accept": "接受 Cookies",
    "copyright": "Copyright © {date} Jackery Inc. All Rights Reserved.",
    "sitemap-link": "#",
    "sitemap": "站点地图"
  },
  "account": {
    "welcome": "欢迎您",
    "email": "邮箱",
    "registerEmail": "注册邮箱",
    "bindNewRegisterEmail": "新邮箱",
    "emailIsEqual": "新绑定的注册邮箱和原邮箱相同，无须修改。",
    "settings": "账户设置",
    "settingTips": "快速管理您的账户资料和账户安全。",
    "titleProfiles": "个人资料",
    "titleSecurity": "账户安全",
    "titleAuthorization": "授权绑定",
    "nickname": "昵称",
    "name": "姓名",
    "gender": "性别",
    "birthday": "生日",
    "contactEmail": "联系邮箱",
    "contactPhone": "联系电话",
    "enterprise": "企业名称",
    "setted": "已设置",
    "unset": "未设置",
    "password": "密码",
    "pop_password": "创建密码",
    "avatarSettings": "头像设置",
    "choosePicture": "选择图片",
    "apply": "应用",
    "secrecy": "保密",
    "male": "男性",
    "female": "女性",
    "currentPassword": "当前密码",
    "newPassword": "新密码",
    "newShowAgain": "不再显示",
    "againPassword": "确认新密码",
    "jackeryIdTips": "Jackery ID只能修改一次, 字符长度在6-32位之间。",
    "birthdayTips": "请告诉我您的生日，以便于为您提供更好的服务。我们会妥善保管您的资料。",
    "nickNameTips": "昵称可以是任意文字和字符，长度不超过32个字符",
    "nameTips": "建议您填写真实的姓名，以便于我们与您联系。我们会妥善保管您的资料。",
    "genderTips": "请告诉我您真实的性别，以便于为您提供更好的服务。我们会妥善保管您的资料。",
    "contactEmailTips": "请设置您常用的邮箱，以便于我么通过该邮箱与您取得联系。我们会妥善保管您的资料。",
    "phoneNumberTips": "请设置您常用的电话号码，以便于我么通过该邮箱与您取得联系。我们会妥善保管您的资料。",
    "phoneNumberTips2": "电话号码只支持数字、括号和连字符",
    "enterpriseNameTips": "如果您是企业账户，可以告诉我们您的企业信息，以便于更好为您提供服务。",
    "emailTips": "注册邮箱是您的登录账户。为了您的账户安全，更换注册邮箱需要进行安全验证。",
    "passwordTips": "为了账户安全，请勿设置过于简单的密码",
    "location": "站点"
  },
  "router": {
    "home": "",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "注册",
    "login": "登录",
    "reset": "重置密码",
    "dashboard": "Dashboard",
    "commonSetting": "通用设置",
    "account": "账户设置",
    "accountOverview": "总览",
    "myProfile": "个人资料",
    "myOrders": "我的订单",
    "personalSettings": "个人设置",
    "addressBook": "地址簿",
    "accountSettings": "帐户设置",
    "myDevices": "我的设备",
    "activate": "账户激活",
    "myService": "我的服务",
    "myReturn": "我的退/换服务",
    "myWishlist": "心愿单",
    "myReferral": "My Referral",
  },
  "description": {
    "home": "",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "创建我的Jackery ID, 加入我们~ 成为Jackery会员。",
    "login": "Jackery ID, 顾客登录",
    "reset": "重置密码",
    "dashboard": "Dashboard",
    "commonSetting": "通用设置",
    "account": "Jackery ID 用户账户中心",
    "accountOverview": "总览",
    "myProfile": "个人资料",
    "myOrders": "我的订单",
    "personalSettings": "个人设置",
    "addressBook": "地址簿",
    "accountSettings": "帐户设置",
    "myDevices": "我的设备",
    "activate": "创建您的密码以激活您的帐户",
    "registerActivate": "请点击按钮激活您的账号, 激活以后您可以使用该账号登录我们的网站",
    "myReturn": "我的退换货"
  },
  "operate": {
    "enable": "启用",
    "disable": "禁用",
    "confirm": "确认",
    "cancel": "取消",
    "remove": "移除",
    "search": "搜索",
    "reset": "重置",
    "add": "新增",
    "close": "关闭",
    "edit": "编辑",
    "yes": "是",
    "no": "否",
    "submit": "提交",
    "next": "下一步",
    "prev": "上一步"
  },
  "tips": {
    "sure": "你确定吗?",
    "placeholder": "请输入",
    "enterConsultContent": "请输入的你咨询内容",
    "all": "所有",
    "custom": "自定义",
    "pending": "待处理",
    "loading": "处理中...",
    "loading2": "处理中",
    "loaded": "已处理",
    "loaded2": "已完成",
    "cancelled": "已取消",
    "changeShop": "切换店铺...",
    "logoutConfirm": "您确定要退出吗?",
    "loggedOut": "注销成功",
    "refreshed": "已刷新",
    "noDescribe": "没有描述",
    "reminder": "温馨提示",
    "commError": "发生了一点问题",
    "reLogin": "请重新登录",
    "verify": "验证码", "sendVerify": "验证码已发送，请您留意您的Email收件箱或者垃圾箱。",
    "getVerifyCode": "获取验证码",
    "verifyError": "验证码不正确",
    "homePage": "首页",
    "404": "抱歉，您访问的页面不存在",
    "404Tips": "请检查输入的网址是否正确，或点击链接继续浏览",
    "500": "哎呀，出了点问题",
    "500Tips": "服务器错误 500，我们深表歉意并正在解决问题。",
    "500Tips2": "请稍后再试。",
    "urlExpired": "验证链接已过期",
    "accountNotActivated": "账户未激活",
    "accountActivatedSuccess": "账户激活成功",
    "requestFrequently": "请勿频繁点击，验证后重新操作",
    "submitSuccess": "提交成功",
    "zipVerifyError": "邮政编码校验失败",
    "otherError": "发生了一点问题, 请联系管理员。 code: {code}",
    "wrongPasswordMultipleTimes": "您连续输入多次错误密码，请{minute}分钟后重试。",
    "networkError": "网络错误",
    "thirdLoginError": "获取用户信息失败，请稍后重试，或用账号密码登录",
    "fileTypeError": "文件类型错误, 只允许上传: {type}类型文件"
  },
  "placeholder": {
    "email": "邮箱 *",
    "emailOrJackeryID": "当前邮箱或Jackery ID *",
    "password": "密码 *",
    "passwordAgain": "确认新密码 *",
    "enterprise": "企业名称",
    "code": "验证码 *",
    "firstName": "名",
    "lastName": "姓",
    "select": "请选择",
    "address1": "街道和号码等",
    "address2": "公寓、单元、建筑、楼层等（可选）",
    "country": "国家/地区",
    "province": "州/省/地区",
    "city": "您的城市",
    "region": "地区",
    "zip": "城市所属的邮政编码",
    "zipError": "邮政编码不正确",
    "phone": "您的电话",
    "company": "公司名称（可选）",
    "isNotEmpty": "此项不能为空",
    "pobox": "我们不送货到邮政信箱",
    "minChar2": "最少2个字符",
    "inputChar": "字符长度在 {length} 位之间"
  },
  "order": {
    "sn": "订单号",
    "detail": "查看物流详情",
    "productName": "产品名称",
    "quantity": "数量",
    "price": "产品价格",
    "status": "订单状态",
    "consult": "咨询",
    "contact": "联系我们",
    "total": "合计",
    "empty": "目前还没有任何订单 ~",
    "questionType": "问题类型",
    "shipped": "已发货",
    "return": "退/换",
    "applied": "已退货退款",
    "revoke": "撤销",
    "serviceType": "请选择服务类型",
    "serviceType_1": "退货退款",
    "serviceType_2": "换货",
    "packageArrived": "是否已收到货",
    "reason": "请选择原因",
    "reason_1": "{day}天无理由退货",
    "reason_2": "产品质量问题",
    "reason_3": "其它",
    "quality": "请输入质量问题",
    "productSerialNumber": "请输入产品序列号",
    "selectReturnProduct": "请选择退换的产品",
    "returnQuantity": "请输入退/换件数",
    "shippingAddress": "收货地址",
    "returnAddress": "退货地址",
    "revise": "修改",
    "uploadVideo": "上传视频",
    "uploadLoading": "视频上传中",
    "videoLimit": "视频大小不能超过{limit}M!",
    "uploadVideoError": "上传视频失败>_<",
    "failureDetermination": "产品故障判断",
    "steps_1": "1. 电站故障排除步骤",
    "steps_1_content": "1) 请按显示屏看指示灯是否亮。\n2) 请按住电源按钮（命名显示）10秒钟以重置电源并放置一段时间后再给电源充电。\n3) 在插入设备并为其充电之前，请按下 AC/DC 插座顶部的按钮以检查灯是否亮起。\n4)请检查您的设备是否可以再次正常充电。\n\n如果上述方法不起作用，请将以下信息发送给我们，以便我们进一步帮助您。\n1)订单号；\n2)序列号（电站底部或电站背面右上角）；\n3) 可以清楚地显示问题的视频（上传到Google Drive并将链接发送给我们）。\n4) 您当前的地址。\n\n",
    "steps_2": "2. 太阳能电池板故障排除步骤",
    "steps_2_content": "1) 请使用提供的交流充电器给电站充电，看是否可以工作。\n2) 请在中午强烈的阳光下测试。将太阳能电池板与发电站连接，确保面板上没有阴影。\n\n如果交流充电器可以很好地为电站充电，请将以下信息发送给我们，以便我们进一步为您提供帮助。\n1) 订单号；\n2) 序列号（在手柄条码下方或USB口对面）；\n3) 可以清楚地显示问题的视频（上传到Google Drive并将链接发送给我们）。\n4) 您当前的地址。",
    "learnMore": "查看更多",
    "shrink": "收起",
    "noReason_30_day": "1. Jackery 为直接从 Jackery.com 购买的商品提供 30 天退款保证。我们相信您会喜欢您的新 Jackery 产品。但是，如果您因任何原因不满意，只需在 30 天内申请退货换货或退款。\n\n2. 请注意：不接受超过 30 天无故退货。\n\n3. 为了根据 30 天退货政策退回您的产品，请检查： 确保退回的产品采用原包装，包括所有材料，处于全新或全新状态.",
    "selectDelivery": "请选择退货物流",
    "enterTrackNumber": "请输入物流跟踪号",
    "illegal_trackNumber": "请输入正确的物流单号",
    "enterDescription": "请输入具体描述"
  },
  "bank": {
    "name": "银行名",
    "branchName": "支行名",
    "type": "银行卡种类",
    "account": "银行卡账号",
    "realName": "持卡人姓名"
  },
  "return": {
    "orderNumber": "订单号",
    "status": "退/换货状态",
    "attachment": "标签附件",
    "confirmRevoke": "请确认是否撤销退/换货申请?",
    "returnPolicy_link": "https://www.jackery.com/pages/policy",
    "returnPolicy_1": "返修/退换货申请常见问题，查看",
    "returnPolicy_2": " '售后政策'; ",
    "returnPolicy_3": "如有问题请联系",
    "returnPolicy_4": "support{'@'}jackery.com",
    "returnPolicy_5": ""
  },
  "devices": {
    "empty": "当前未找到任何设备",
    "more": "更多",
    "extendedWarrantyDeadline": "产品质保到期时间",
    "warrantyStatus": "保修状态",
    "existWarranty": "已延保",
    "nonExistentWarranty": "无延保",
    "userManual": "用户手册",
    "warrantyInfo": "单击'提交'激活您的延长保修。",
    "newsletter": "订阅我们",
    "warrantySuccess": "延保成功",
    "download": "下载",
    "none": "暂无",
  },
  "address": {
    "empty": "暂无保存地址 ~",
    "add": "添加新地址",
    "edit": "编辑地址",
    "userName": "用户名",
    "address1": "地址1",
    "address2": "地址2",
    "country": "国家/地区",
    "country_code": "国家/地区编码",
    "province": "州/省/地区",
    "province_code": "州/省/地区编码",
    "city": "城市",
    "zip": "邮政编码",
    "phone": "电话",
    "company": "公司",
    "default": "设置为默认送货地址",
    "isDefault": "默认地址",
    "error": "收货地址存在异常或信息不完整，请检查"
  },
  "character": {
    "comma": ","
  },
  // Following is server language pack
  "msg": {
    "success": "成功"
  },
  "user": {
    "log": {
      "expire": "请重新登录"
    }
  },
  "message": {
    "order_status_shipping": "已发货",
    "order_status_completed": "已完成",
    "order_status_processing": "处理中",
    "order_status_archived": "已发货",
    "order_status_canceled": "已取消",
    "order_status_unpaid": "未支付",
    "device_not_found": "设备不存在",
    "device_disable_can_not_set_warranty": "当前设备无效，无法设置延保",
    "device_was_warranty": "当前设备已延保",
    "device_not_support_warranty": "当前设备不支持延保"
  },
  "create": {
    "address": {
      "fail": "添加地址失败"
    }
  },
  "activation": {
    "email": {
      "expire": "邮件已失效"
    }
  },
  "select_country": {
    "title": "Select Your Country/Region"
  },
  // My Jackery Life 
  "jackeryLife": {
    'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/Jackery_life_b7f56b9a-0b8b-491c-820f-6062c368494a.png?v=1682566323',
    'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/Jackery_life_afcd4950-2e96-4421-9202-7a40b56a86e4.png?v=1682566392',
    'text': 'Join Jackery Life! Share your stories with Jackery, and have a chance to win great prizes!',
    'learnMore': 'Learn more',
  },
  "old-invite-new": {
    "myRewardsTitle": "My Rewards",
    "myRewardsSubTitle": "Manage and use rewards that have been claimed here. For expired and used rewards, please check them in My Wallet.",
    "myRewardsNoData": "Invite your friends to subscribe or purchase, and claim your rewards on the dashboard below.",
    "viewMore": "View More",
    "myReferralsTitle": "My Referrals",
    "myReferralsSubTitle": "Track the referral progress and claim the rewards. Click the reward for details.",
    "myReferralsNoData": "Invite your friends to subscribe or make a purchase, then claim your rewards here.",
    "claim": "Claim",
    "claimed": "Claimed",
    "expired": "Expired",

    // 补充
    "myReferral": "My Referral",
    "enterEmail": "Enter Your Email Address",
    "invalidEmail": "Invalid Email Address",
    "messageSent": "Message Sent",
    "copy": "COPY",
    "copied": "COPIED",
    "copiedSuccess": "COPIED SUCCESS!",
    "friendEmail": "Enter Your Friend's Email",
    "referee": "Referee",
    "referralType": "Referral Type",
    "rewards": "Rewards",
    "status": "Status",
    "instagramCopySuccessTip": "Referral Link has copied, paste and share to Instagram",
  }
}
